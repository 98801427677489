import {useState, useEffect} from 'react';
import { useSwipeable } from 'react-swipeable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowsLeftRight, faArrowDown, faEraser, faCalculator } from '@fortawesome/free-solid-svg-icons'
import './App.css';

function App() {


  const [tOneScore, setTOneScore] = useState(
    (localStorage.getItem("tOneScore") === null) ? 0 : localStorage.getItem("tOneScore")
  )

  const [tTwoScore, setTTwoScore] = useState(
    (localStorage.getItem("tTwoScore") === null) ? 0 : localStorage.getItem("tTwoScore")
  )

  const [totalScore, setTotalScore] = useState(0)

  const [dialogOpen, setDialogOpen] = useState(false)

  const [switchNow, setSwitchNow] = useState(false)
  const [switchAt, setSwitchAt] = useState(7)

  useEffect(() => {
    localStorage.setItem("tOneScore", tOneScore);
    localStorage.setItem("tTwoScore", tTwoScore);
    }, [tOneScore, tTwoScore]);


  useEffect(() => {
    setTotalScore(parseInt(tOneScore)+parseInt(tTwoScore))
  }, [tOneScore, tTwoScore]);

  useEffect(() => {
    console.log(totalScore)
    if (totalScore % switchAt == 0 && totalScore != 0) {
      console.log("switch now")
      setSwitchNow(true)
    } else {
      if (switchNow) {
        setSwitchNow(false)
      }
    }
  }, [totalScore]);

  function handleContainerLeft(e) {
    e.preventDefault();
    setTOneScore(parseInt(tOneScore) + 1)
  }

  function handleMinusLeft(e) {
    e.stopPropagation();
    e.preventDefault();
    setTOneScore((parseInt(tOneScore) > 0) ? parseInt(tOneScore) - 1 : 0)
  }

  function handleResetLeft(e) {
    e.stopPropagation();
    e.preventDefault();
    setTOneScore(0);
  }

  function handleContainerRight(e) {
    e.preventDefault();
    setTTwoScore(parseInt(tTwoScore) + 1)
  }

  function handleMinusRight(e) {
    e.stopPropagation();
    e.preventDefault();
    setTTwoScore((parseInt(tTwoScore) > 0) ? parseInt(tTwoScore) - 1 : 0)
  }

  function handleResetRight(e) {
    e.stopPropagation();
    e.preventDefault();
    setTTwoScore(0);
  }

  function handleResetBothClick(e) {
    e.stopPropagation();
    e.preventDefault();
    setTOneScore(0);
    setTTwoScore(0);
  }

  function handleOpenSetSwitchAt(e) {
    e.stopPropagation();
    e.preventDefault();
    setDialogOpen(!dialogOpen)
  }

  function handleSetSwitchAt(e) {
    setSwitchAt(e.target.value)
  }

  const [style, setStyle] = useState(
    (localStorage.getItem("flip") === null) ? "" : localStorage.getItem("flip")
  )
  function handleSwitchSides(e) {
    e.preventDefault();
    setStyle(
      (style === ""? "container-flip" : "")
    )
  }

  useEffect(() => {
    localStorage.setItem("flip", style);
    }, [style]);

  const leftHandlers = useSwipeable({
    onSwipedUp: () => setTOneScore(parseInt(tOneScore) + 1),
    onSwipedDown: () => setTOneScore(parseInt(tOneScore) - 1),
    preventScrollOnSwipe: true,
  });


  const rightHandlers = useSwipeable({
    onSwipedUp: () => setTTwoScore(parseInt(tTwoScore) + 1),
    onSwipedDown: () => setTTwoScore(parseInt(tTwoScore) - 1),
    preventScrollOnSwipe: true,
  });

  return (
    <div className="App">
      <div className={`${style} container-score`}>
        <div {...leftHandlers} className="container-team" id="container-left" onClick={handleContainerLeft}>
          <p>Team 1</p>
          <h2>{tOneScore}</h2>
          <div className="container-team-control">
            <FontAwesomeIcon icon={faArrowDown} className="container-team-button button-minus" onClick={handleMinusLeft}/>
            <FontAwesomeIcon icon={faEraser} className="container-team-button button-reset" onClick={handleResetLeft}/>
          </div>
        </div>
        <div className="container-control">
          <FontAwesomeIcon icon={faCalculator} className="container-control-button" onClick={handleOpenSetSwitchAt}/>
          <FontAwesomeIcon icon={faArrowsLeftRight} className={ `container-control-button ${switchNow ? "switch-now" : ""}` } onClick={handleSwitchSides}/>
          <FontAwesomeIcon icon={faEraser} className="container-control-button" onClick={handleResetBothClick}/>
        </div>
        <div {...rightHandlers} className="container-team" id="container-right" onClick={handleContainerRight}>
          <p>Team 2</p>
          <h2>{tTwoScore}</h2>
          <div className="container-team-control">
            <FontAwesomeIcon icon={faArrowDown} className="container-team-button button-minus" onClick={handleMinusRight}/>
            <FontAwesomeIcon icon={faEraser} className="container-team-button button-reset" onClick={handleResetRight}/>
          </div>
        </div>
      </div>
      {dialogOpen && <div className='container-switch' onClick={handleOpenSetSwitchAt}>
        <div className='container-switch-control' onClick={(e) => e.stopPropagation()}>
          <label>Switch sides at:</label>
          <input className="input-switch" type="number" value={switchAt} onChange={(e) => handleSetSwitchAt(e)} />
          <button className='button-switch' onClick={handleOpenSetSwitchAt}>Save</button>
        </div>
      </div> }
    </div>
  );
}

export default App;
